import React, { useEffect, useState } from "react";
import { Image, ImageSourcePropType, StyleSheet, TouchableOpacity, Platform, View, Dimensions, DimensionValue } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useGlobalState } from "../../../shared/state";
import TextTitle from "../../Text/TextTitle";

interface Props {
  color: string;
  text?: string;
  icon?: ImageSourcePropType;
  navigateTo: string;
  navigateOptions?: any;
  deactivated?: boolean;
  onPress?: any;
}

const SquareButton = ({ color, text, icon, navigateOptions, navigateTo, deactivated = false, onPress }: Props) => {
  const [route, setRoute] = useGlobalState("currentScreen");
  const [, setModalIsDisplayed] = useGlobalState("modalIsDisplayed");
  const [measurements] = useGlobalState("currentMeasurements");
  const [currentScanner] = useGlobalState("currentScanner");
  const [Colors] = useGlobalState("colors");
  const [currentRoute, setCurrentRoute] = useState<string>("Home");
  const [iconWidth, setIconWidth] = useState<DimensionValue | undefined>("40%");
  const [iconHeight, setIconHeight] = useState<DimensionValue | undefined>("40%");

  const navigation = useNavigation();

  useEffect(() => {
    const routeIndex = navigation.getState().index;
    const routeName = navigation.getState().routes[routeIndex].name;
    setCurrentRoute(routeName);
  }, [navigation]);

  const navigate = (route: string | undefined, options: any = undefined) => {
    if (route === "Logout") {
      let redirectTo: string = Platform.OS === "web" ? route : measurements && currentRoute.includes("Measurement") ? "LogoutMeasurementModal" : "LogoutModal";
      setModalIsDisplayed(true);
      navigation.push(redirectTo, options);
      setRoute(redirectTo);
    } else if (route === "NewScanGenderSelection" && measurements !== undefined) {
      let redirectTo: string = Platform.OS === "web" ? "NewScan" : currentRoute.includes("Measurement") ? "NewScanMeasurementModal" : "NewScanModal";
      setModalIsDisplayed(true);
      navigation.push(redirectTo, options);
      setRoute(redirectTo);
    } else if (route && route.includes("Modal")) {
      setModalIsDisplayed(true);
      navigation.push(route, options);
      setRoute(route);
    } else if (route === "DetectedScanners") {
      navigation.replace(route, options);
      setRoute(route);
    } else if (route === "CloseButton") {
      navigation.goBack();
    } else if (route === "Measurements" && Platform.OS === "web") {
      navigation.push(route, options);
      setRoute(route);
    } else if (route) {
      navigation.navigate(route, options);
      setRoute(route);
    }
  };

  const btnColor: string = deactivated ? "white" : currentRoute.includes(navigateTo) ? Colors.mainColorDarkHover.color : color;

  const buttonStyle = "squareButton";
  const iconContainerStyle = "iconContainer";

  useEffect(() => {
    if (
      (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "hoffmann group") ||
      (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten")
    ) {
      setIconHeight("70%");
      setIconWidth("70%");
    }
  }, [currentScanner]);

  const toProps = {
    // @ts-ignore
    style: [styles.squareButton, { backgroundColor: btnColor }],
    onPress: deactivated ? undefined : onPress ? () => onPress() : () => navigate(navigateTo, navigateOptions),
    disabled: [currentRoute != navigateTo || route != navigate, currentRoute === "DetectedScanners"] ? false : true,
  };

  const textTitleProps = {
    color: btnColor === "white" ? "black" : "white",
    fontWeight: "900",
    fontSize: 21,
  };

  return (
    <TouchableOpacity {...toProps}>
      {text && <TextTitle text={text} {...textTitleProps} />}
      {icon && <Image source={icon} style={[styles.iconContainer, { backgroundColor: color, width: iconWidth, height: iconHeight }]} />}
    </TouchableOpacity>
  );
};

export default SquareButton;

const styles = StyleSheet.create({
  squareButton: {
    width: Platform.OS === "web" ? "7%" : "10.7%",
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    flex: 1,
    resizeMode: "contain",
  },
});
