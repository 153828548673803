import React from "react";
import { Image, View, StyleSheet, TouchableOpacity, Text, ImageBackground } from "react-native";
import { background, Icons } from "../../assets";
import { useNavigation } from "@react-navigation/native";
import { useGlobalState } from "../../shared/state";
import ProductService from "../../shared/services/products-service";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { CartItem } from "../../shared/model/types";
import TextTitle from "../../components/Text/TextTitle";
import i18n from "../../i18nSets/i18n";
import { removeItemFromCart, updateItemStock } from "./cartFunctions";
import _ from "lodash";
import { Styles } from "../../styles";
import ScannerCoreService from "../../shared/services/core-services";
import ScannerService from "../../shared/services/scanner-service";

const CartModal: React.FC = (): JSX.Element => {
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [currentUser] = useGlobalState("currentUser");
  const [Colors] = useGlobalState("colors");
  const [selectedShoe] = useGlobalState("selectedShoe");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [carts, setCarts] = useGlobalState("carts");
  // const [carts, setCarts] = useState({
  //   "b0f969e0-dab2-4d83-83ce-ef841d1b864b": {
  //     type: "elten-ortho",
  //     store_id: "C00086",
  //     employee_number: "gp001",
  //     items: [
  //       {
  //         type: "orthopedic-insole",
  //         additional_product_gtin: "4030839367087",
  //         comments: "Schuhe GTIN: 04030839228005, Links:  , Rechts: ",
  //         itemName: "Orthopedic Insole",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 1,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe",
  //         gtin: "04030839228005",
  //         itemName: "RENZO GTX Mid ESD S3 CI",
  //         itemIcon: "https://onefid-core-test.ams3.digitaloceanspaces.com/products/102254715.jpg",
  //         itemID: 2,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382899",
  //         comments: "",
  //         itemName: "BEINLÄNGENAUSGLEICH BIS 1,5 CM",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 3,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382882",
  //         comments: "",
  //         itemName: "BEINLÄNGENAUSGLEICH BIS 1,5 CM",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 4,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382912",
  //         comments: "",
  //         itemName: "BEINLÄNGENAUSGLEICH BIS 3 CM",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 5,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382905",
  //         comments: "",
  //         itemName: "BEINLÄNGENAUSGLEICH BIS 3 CM",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 6,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382943",
  //         comments: "Links:  , Rechts: ",
  //         itemName: "INNEN-/AUßENRANDERHÖUNG ",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 7,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382981",
  //         comments: "",
  //         itemName: "KORKKEIL",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 8,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382974",
  //         comments: "",
  //         itemName: "KORKKEIL",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 9,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382998",
  //         comments: "Links:  , Rechts: ",
  //         itemName: "HAGLUNDFERSE",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 10,
  //         stock: 1,
  //       },
  //       {
  //         type: "shoe-adaption",
  //         additional_product_gtin: "4030839382929",
  //         comments: "",
  //         itemName: "ABROLLSOHLE",
  //         itemIcon: "/static/media/sole-icon.7a76d3d26c534f509132.png",
  //         itemID: 11,
  //         stock: 1,
  //       },
  //     ],
  //   },
  //   "7b9474d5-fdd8-4b49-a3bf-e17b332f2347": {
  //     type: "standard",
  //     store_id: "C00086",
  //     employee_number: "gp001",
  //     items: [
  //       {
  //         type: "shoe",
  //         gtin: "04030839228005",
  //         itemName: "RENZO GTX Mid ESD S3 CI",
  //         itemIcon: "https://onefid-core-test.ams3.digitaloceanspaces.com/products/102254715.jpg",
  //         itemID: 1,
  //         stock: 1,
  //       },
  //       {
  //         type: "standard-insole",
  //         additional_product_gtin: "4030839247747",
  //         itemName: "ELTEN SensiCare Medium",
  //         itemIcon: "https://eltentransfer.com/Produktbilder/ACC_204081_MAIN.jpg",
  //         itemID: 2,
  //         stock: 1,
  //       },
  //       {
  //         type: "standard-insole",
  //         additional_product_gtin: "4030839323519",
  //         itemName: "Artisan Sole ESD Level 2",
  //         itemIcon: "https://eltentransfer.com/Produktbilder/ACC_204154_MAIN.jpg",
  //         itemID: 3,
  //         stock: 1,
  //       },
  //     ],
  //   },
  // });
  const navigation = useNavigation();
  console.log("CARTS ==", carts);

  const mapShoeCategoryForElten = (onefidCategory: string) => {
    if (onefidCategory === "low-work-shoes") {
      return "Halbschuhe";
    } else if (onefidCategory === "open-work-shoes") {
      return "Sandale";
    } else if (onefidCategory === "work-boots") {
      return "Stiefel";
    }
    return "";
  };

  const handleSubmission = () => {
    const allPromises: Promise<any>[] = []; // Array to track all addCartItem promises
    let orthoInsole = false;
    let insoleApplianceId: string;
    let insoleOrderNr: string;
  
    Object.keys(carts).forEach(async (cartUUID: string) => {
      const cart = carts[cartUUID];
  
      if (cart.type === "elten-ortho") {
        const items = Object.values(cart.items);
        const shoeItem: any = items.find((item: any) => item.type === "shoe");

        if (shoeItem) {
          const shoeData = {
            type: shoeItem.type,
            ...(shoeItem.gtin && { gtin: shoeItem.gtin }),
            ...(shoeItem.additional_product_gtin && { additional_product_gtin: shoeItem.additional_product_gtin }),
            ...(shoeItem.store_id && { store_id: shoeItem.store_id }),
            ...(shoeItem.store_partner && { store_partner: shoeItem.store_partner }),
            ...(shoeItem.comments && { comments: shoeItem.comments }),
            ...(shoeItem.insole_set && { comments: shoeItem.insole_set }),
          };
  
          const shoePromise = ProductService.addCartItem(cartUUID, shoeData).then((shoeResponse: any) => {
            const applianceId = shoeResponse?.appliances_id;
            console.log(`Shoe item added, applianceId: ${applianceId}`);
  
            if (applianceId) {
              items.forEach((cartItem: any) => {
                if (cartItem.type === "shoe-adaption") {
                  const adaptionData = {
                    type: cartItem.type,
                    appliances_id: applianceId,
                    ...(cartItem.gtin && { gtin: cartItem.gtin }),
                    ...(cartItem.additional_product_gtin && { additional_product_gtin: cartItem.additional_product_gtin }),
                    ...(cartItem.store_id && { store_id: cartItem.store_id }),
                    ...(cartItem.store_partner && { store_partner: cartItem.store_partner }),
                    ...(cartItem.comments && { comments: cartItem.comments }),
                    ...(cartItem.insole_set && { insole_set: cartItem.insole_set }),
                  };
                  allPromises.push(ProductService.addCartItem(cartUUID, adaptionData));
                }
              });
            }
          });
  
          allPromises.push(shoePromise);
        }

        items.forEach((cartItem: any) => {
          if (cartItem.type === "orthopedic-insole") {
            // Set orthoInsole to true if an orthopedic-insole item is found
            // to trigger move-file endpoint to generate txt file and move scn files for GP Filiale
            orthoInsole = true;
            const insoleData = {
              type: cartItem.type,
              ...(cartItem.gtin && { gtin: cartItem.gtin }),
              ...(cartItem.additional_product_gtin && { additional_product_gtin: cartItem.additional_product_gtin }),
              ...(cartItem.store_id && { store_id: cartItem.store_id }),
              ...(cartItem.store_partner && { store_partner: cartItem.store_partner }),
              ...(cartItem.comments && { comments: cartItem.comments }),
              ...(cartItem.insole_set && { insole_set: cartItem.insole_set }),
            };
            insoleOrderNr = cart.orderNr;
            const insolePromise = ProductService.addCartItem(cartUUID, insoleData).then((response: any) => {
              insoleApplianceId = response.appliances_id;
            });
            allPromises.push(insolePromise);
          }
        });
  
        const orderData = {
          type: cart.type,
          store_id: cart.store_id,
          store_partner: cart.store_partner,
          ...(cart.email && { email: cart.email }),
          ...(cart.employee_number && { employee_number: cart.employee_number }),
          ...(cart.anonymous_user_uuid && { anonymous_user_uuid: cart.anonymous_user_uuid }),
        };
        ProductService.completeOrder(cartUUID, orderData);
  
      } else {
        // Handle non "elten-ortho" cart items
        Object.values(cart.items).forEach((cartItem: any) => {
          const cartItemData = {
            type: cartItem.type,
            ...(cartItem.gtin && { gtin: cartItem.gtin }),
            ...(cartItem.store_id && { store_id: cartItem.store_id }),
            ...(cartItem.store_partner && { store_partner: cartItem.store_partner }),
            ...(cartItem.additional_product_gtin && { additional_product_gtin: cartItem.additional_product_gtin }),
            ...(cartItem.comments && { comments: cartItem.comments }),
            ...(cartItem.insole_set && { insole_set: cartItem.insole_set }),
          };
          allPromises.push(ProductService.addCartItem(cartUUID, cartItemData));
          const orderData = {
            type: cart.type,
            store_id: cart.store_id,
            store_partner: cart.store_partner,
            ...(cart.email && { email: cart.email }),
            ...(cart.employee_number && { employee_number: cart.employee_number }),
            ...(cart.anonymous_user_uuid && { anonymous_user_uuid: cart.anonymous_user_uuid }),
          };
          ProductService.completeOrder(cartUUID, orderData);
        });
      }
    });
  
    Promise.all(allPromises)
      .then((responses) => {
        if (orthoInsole) {
          const storeID = customerStoreID || currentScanner.storeid;
          const folderPath = currentScanner && currentScanner.folderPath;
          ScannerCoreService.getScanDeviceInfo(currentScanner && currentScanner.deviceID).then((device) => {
            ScannerService.moveFileFromScannerHost(
              folderPath,
              currentScanner.gpManagerPath,
              currentScanner.gpManagerParams,
              currentUser.firstName,
              currentUser.lastName,
              currentUser.dateOfBirth,
              insoleApplianceId,
              new Date().toLocaleString(),
              storeID,
              device.device_identifier,
              mapShoeCategoryForElten(selectedShoe.categoryId),
              selectedShoe.name,
              selectedShoe.size.eu,
              insoleOrderNr
            );
          });
        }
        setCarts([]); // Clear carts only if all additions succeeded
        navigation.navigate("MainApp");
      })
      .catch((error) => {
        console.error("Error adding items to cart:", error);
      });
  };
  

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={false}>
        <View style={styles.modalWrapper}>
          <TouchableOpacity style={[styles.backButton, {backgroundColor: Colors.mainColorDark.color}]} onPress={() => navigation.navigate(!_.isEmpty(carts) ? "ProductDetail" : currentMeasurements ? "Measurements" : "MainApp")}>
            <Image source={Icons.backArrowIcon} style={styles.iconContainer} />
          </TouchableOpacity>
          <View style={styles.formWrapper}>
            <Text style={styles.titleText}>{i18n.t("items")}</Text>
            {!_.isEmpty(carts) ? (
              <>
                <View style={styles.optionsViewWrapper}>
                  {Object.keys(carts).map((cart: string) => {
                    return Object.values(carts[cart].items).map((cartItem: CartItem) => {
                      return (
                        <View style={styles.shoeItemsWrapper} key={cartItem.itemID}>
                          <View style={styles.infoView}>
                            <View style={styles.inputsWrapper}>
                              <TextTitle fontSize={12} text={cartItem.itemName} />
                            </View>
                          </View>
                          <View style={styles.itemImageView}>
                            <Image source={{ uri: cartItem.itemIcon }} style={styles.imageContainer} />
                          </View>
                          {/* <View style={styles.checkboxView}>
                        <TouchableOpacity style={styles.iconsWrapper} onPress={() => updateItemStock(setCarts, cart, cartItem.itemID, -1)}>
                          <Text style={styles.buttonText}>-</Text>
                        </TouchableOpacity>
                        <TextTitle fontSize={12} text={cartItem.stock?.toString()} />
                        <TouchableOpacity style={styles.iconsWrapper} onPress={() => updateItemStock(setCarts, cart, cartItem.itemID, 1)}>
                          <Text style={styles.buttonText}>+</Text>
                        </TouchableOpacity>
                      </View> */}
                          <View style={styles.checkboxView}>
                            <TouchableOpacity style={styles.iconsWrapper} onPress={() => removeItemFromCart(setCarts, cart, cartItem.itemID)}>
                              <Text>{i18n.t("removeButton")}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      );
                    });
                  })}
                </View>
                <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color, alignSelf: "center" }]} onPress={() => handleSubmission()}>
                  <Text style={[styles.buttonText, { color: "#ffffff" }]}>{i18n.t("checkoutButton")}</Text>
                </TouchableOpacity>
              </>
            ) : (
              <View style={styles.emptyTextWrapper}>
                <Text style={[styles.titleText, { color: "#666666" }]}>{i18n.t("cartIsEmpty")}</Text>
              </View>
            )}
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default CartModal;

const styles = StyleSheet.create({
  backButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    left: "-10%",
    top: 0,
    zIndex: 10,
    paddingTop: "2%",
    paddingLeft: "2%",
  },
  iconContainer: {
    height: '50%',
    width: '50%',
    resizeMode: 'contain'
  },
  modalWrapper: {
    flex: 1,
    width: "65%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "100%",
    height: "100%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  inputsWrapper: {
    width: "100%",
    height: "100%",
    paddingLeft: "1%",
    paddingRight: "1%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
    fontSize: 72,
  },
  iconsWrapper: {
    width: "50%",
    height: "100%",
    // paddingLeft: "1%",
    // paddingRight: "1%",
    // marginLeft: "3%",
    // marginRight: "3%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "center",
    flexDirection: "row",
    alignSelf: "center",
    fontSize: 52,
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "center",
    color: "#000000",
    textTransform: "uppercase",
  },
  optionsViewWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flex: 1,
  },
  shoeItemsWrapper: {
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
    flexDirection: "row",
    maxHeight: "10%"
  },
  infoView: {
    width: "40%",
    height: "90%",
  },
  checkboxView: {
    width: "15%",
    height: "auto",
    alignItems: "center",
    flexDirection: "row",
  },
  itemImageView: {
    width: "25%",
    height: "-webkit-fill-available",
    alignItems: "center",
    flexDirection: "row",
  },
  imageContainer: {
    flex: 1,
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  },
  emptyTextWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
