import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Icons } from '../../assets';
import I18n from '../../i18nSets/i18n';
import { useGlobalState } from '../../shared/state';
import Button from '../Buttons/generic-button/Button';
import { I18N } from '../../shared/util';
import { useNavigation } from '@react-navigation/native';
import SquareButton from '../Buttons/generic-button/SquareButton';

type Props = {
  showMenuButtons: boolean;
  showHomeButton: boolean;
  showStartButton: boolean;
  showLanguageButton: boolean;
  hideLeftButton: boolean;
  hideNewScanButton: boolean;
};

const Footer: React.FC<Props> = ({ showHomeButton, showStartButton, showMenuButtons, showLanguageButton, hideLeftButton, hideNewScanButton }: Props): JSX.Element => {
  const [currentScanner] = useGlobalState('currentScanner');
  const [language, setLanguage] = useGlobalState('language');
  const [footerMenu] = useGlobalState('footerMenu');
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [currentUser] = useGlobalState('currentUser');
  const [Colors] = useGlobalState('colors');
  const [leftButtonIcon, setLeftButtonIcon] = useState<any>();

  const i18n = I18N(language);

  const toggleLanguage = () => {
    const newLang = language === "en" ? "de" : "en";
    setLanguage(newLang);
    I18n.locale = newLang;
  };

  const langButtonText = language === "en" ? "de" : "en";
  const navigation = useNavigation();
  const routeIndex = navigation.getState().index
  const routeName = navigation.getState().routes[routeIndex].name

  const generateButton = (buttonConfig: any) => {
    const { color = "white", icon = undefined, onPress = undefined, navigateTo = undefined } = buttonConfig;
    const text = I18n.t(buttonConfig.text, { locale: language });

    const buttonProps = { color, text, icon, navigateTo, onPress };
    Object.keys(buttonProps).forEach((prop: string) => {
      if (buttonProps[prop] === undefined) delete buttonProps[prop];
    });

    return <SquareButton {...buttonProps} />;
  };
  const isNotOrthoOrder = {isOrthoOrder: false}

  useEffect(()=>{
    if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten") {
      setLeftButtonIcon(Icons.eltenIcon);
    } else if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "hoffmann group") {
      setLeftButtonIcon(Icons.hoffmanIcon);
    } else if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv") {
      setLeftButtonIcon(Icons.onefidIcon);
    } else if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "steitz") {
      setLeftButtonIcon(Icons.steitzIcon);
    } else {
      setLeftButtonIcon(Icons.onefidIcon);
    }
  },[currentScanner])

  const leftButton = hideLeftButton ? (
    <View></View>
  ) : showHomeButton ? (
    <SquareButton
      color="white"
      icon={leftButtonIcon}
      navigateTo={currentMeasurements ? "Measurements" : "Home"}
    />
  ) : routeName === 'DetectedScanners' ? (
    <SquareButton
      color={Colors.mainColorDarkHover.color}
      icon={Icons.refreshIcon}
      navigateTo={'DetectedScanners'}
    />
  ) : (
    <SquareButton
      color={Colors.mainColorDarkHover.color}
      icon={leftButtonIcon}
      navigateTo={currentMeasurements ? 'Measurements' : 'Home'}
    />
  );
  
  const getNextScreen = () => {
    if (currentScanner && currentScanner["mandatoryLogin"]) {
      if (currentUser) {
        return "NewScanGenderSelection";
      } else {
        if (Platform.OS === "web") {
          return "Login";
        } else {
          return "LoginModal";
        }
      }
    } else {
      return "NewScanGenderSelection";
    }
  }

  const rightButton =
    footerMenu && footerMenu.rightButton ? (
      generateButton(footerMenu.rightButton)
    ) : showStartButton ? (
      <SquareButton color="white" text={I18n.t("start", { locale: language })} navigateTo="Scan" />
    ) : showLanguageButton ? (
      <SquareButton color="black" text={langButtonText.toUpperCase()} onPress={toggleLanguage} navigateTo="" />
    ) : hideNewScanButton ? undefined : (
      <SquareButton
        color={Colors.mainColorLight.color}
        text={I18n.t("new scan", { locale: language })}
        navigateTo={getNextScreen()}
        navigateOptions={{ resetState: true }}
        deactivated={currentScanner === undefined}
      />
    );

  return (
    <View style={styles.footer}>
      {leftButton}
      {showMenuButtons && (
        <View style={styles.footerMiddle}>
          {currentScanner && currentScanner.enableCatalogButton && (
            <Button
              color="white"
              text={i18n.t("catalog")}
              navigateTo={currentScanner.components.length === 1 ? currentScanner.components[0].route : "Catalog"}
              navigateOptions={isNotOrthoOrder}
              deactivated={currentMeasurements ? false : true}
            />
          )}
          {currentScanner && currentScanner.insoleRecommendation && currentScanner.insoleRecommendation.displayAsFooterButton && (
            <Button color="white" text={i18n.t("insole.caption")} navigateTo="InsoleRecommendation" deactivated={currentMeasurements ? false : true} />
          )}
          {/* {currentScanner && currentScanner.enableOrthopedicButton &&(
            <Button
              color="white"
              text="Ortho"
              navigateTo= "OrthoRegisterModal"
              deactivated={currentMeasurements ? false : true}
            />
          )} */}
        </View>
      )}
      {rightButton}
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  footer: {
    height: "13%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerMiddle: {
    flex: 1,
    flexDirection: "row",
    marginLeft: "0.2%",
  },
});
