import React, { useEffect, useState } from "react";
import { ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { background } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useNavigation } from '@react-navigation/native';
import { ScannerConfigDTO } from "../../shared/model/types";
import { getLocalIP, scanNetwork } from "../../shared/network";
import ScannerService from "../../shared/services/scanner-service";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import { Fonts, Styles, Theme } from "../../styles";
import NetworkScan from "./NetworkScan";
import DetectedScannerCard from "./DetectedScannerCard";
import I18n from "../../i18nSets/i18n";

const updateLanguage = (language, setLanguage: (lang: string) => void) => {
  if (language === 'en') {
    setLanguage(language);
    I18n.locale = language;
  }
  if (language === 'de') {
    setLanguage(language);
    I18n.locale = language;
  }
}
const DetectedScanners: React.FC = (): JSX.Element => {
  const [currentScanner, setCurrentScanner] = useGlobalState("currentScanner");
  const [, setCurrentScreen] = useGlobalState("currentScreen");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [language, setLanguage] = useGlobalState('language');

  const i18n = I18N(language);
  const navigation = useNavigation();
  const redirectTo = currentMeasurements ? "Measurements" : "Home";

  const [ip, setIp] = useState<any>(undefined);
  const [scanners, setScanners] = useState<ScannerConfigDTO[]>([]);
  const [scanProgress, setScanProgress] = useState<number>(0);
  const [scanFinished, setScanFinished] = useState<Boolean>(false);
  const [,setColors] = useGlobalState('colors');

  useEffect(() => {
    if (ip) {
      setScanFinished(false);
      scanNetwork(ip, undefined, setScanProgress)
        .then((foundScanners: ScannerConfigDTO[]) => {
          if (foundScanners.length === 1) {
            setScanners(foundScanners);
            selectScanner(foundScanners[0]);
          } else {
            setScanners(foundScanners);
          }
        })
        .finally(() => setScanFinished(true));
    }
  }, [ip]);

  useEffect(()=>{
    if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv") {
      setColors(Theme.ssvColors)
    } else if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "steitz") {
      setColors(Theme.steitzColors)
    } else if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten") {
      setColors(Theme.eltenColors)
    } else if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "hoffmann group") {
      setColors(Theme.hofmannColors)
    }
  },[currentScanner])

  const selectScanner = (scanner: ScannerConfigDTO) => {
    AsyncStorage.removeItem("authtoken");
    AsyncStorage.setItem("authtoken", scanner["scanner-token"]);
    ScannerService.getScannerInfo(scanner["scanner-token"])
      .then(res => {
        const scannerConfig = Object.assign(res, scanner)
        setCurrentScanner(scannerConfig);
        if (scannerConfig["scanner-language"]) updateLanguage(scannerConfig["scanner-language"], setLanguage)
        navigation.replace(redirectTo);
        setCurrentScreen(redirectTo);
      })
      .catch(e => console.log("ERROR getScannerInfo :", e));
  };

  useEffect(() => {
    if (!globalThis.env.MOCK_PARTNER_TOKEN) {
      Platform.OS === "web"
        ? setIp("localhost")
        : getLocalIP()
          .then(setIp)
          .catch(e => console.log(e));
    } else if (!currentScanner && globalThis.env.MOCK_PARTNER_TOKEN) {
      selectScanner({
        "scanner-address": "0.0.0.0",
        "scanner-name": "Mock Scanner",
        "scanner-token": globalThis.env.MOCK_PARTNER_TOKEN
      });
      setScanProgress(100);
      setScanFinished(true);
    }

  }, []);

  const onSelectScanner = (scanner: ScannerConfigDTO) => selectScanner(scanner);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false}>
        <View style={styles.contentWrapper}>
          {ip && !scanFinished && <NetworkScan progress={scanProgress} scanners={scanners} />}
          {ip && scanners.length > 1 && scanFinished && scanners.map((scanner: ScannerConfigDTO, index: number) => <DetectedScannerCard key={index} scannerInfo={scanner} onPress={onSelectScanner} />)}
          {ip && scanners.length === 0 && scanFinished && <Text style={[Fonts.mBlack, Fonts.size30]}>{i18n.t("no scanner found")}</Text>}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default DetectedScanners;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  }
});
